import { LineChart, ResponsiveContainer, XAxis, Line, Tooltip } from "recharts";
import { toDollars, formatDollars } from "./lib";

function generateData(projections, cashProjections) {
    return projections.map((x, i) => ({
        "name": x[0],
        "invest": toDollars(x[1]),
        "cash": toDollars(cashProjections[i][1])
    }));
}

export default function Chart({ projections, cashProjections }) {
    return (
        <ResponsiveContainer width="100%" height="100%" minHeight="300px">
            <LineChart data={generateData(projections, cashProjections)} margin={{ top: 0, right: 30, left: 30, bottom: 0 }}>
                <XAxis dataKey="name"/>
                <Tooltip formatter={(value, name, props) => formatDollars(value)} />
                <Line dataKey="invest" stroke="#82ca9d" />
                <Line dataKey="cash" stroke="#8884d8" />
            </LineChart>
        </ResponsiveContainer>
    );
}