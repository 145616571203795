

function assert(condition, message) {
    if(!condition) {
        throw message;
    };
}

function amount(current, ratePerc, addition) {
    return current + (current * ratePerc) + addition;
}


export function toCents(dollars) {
    return dollars * 100;
}

export function toDollars(cents) {
    return cents / 100;
}

export function formatDollars(dollars) {
    return dollars.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
}

export function projection(startAge, stopAge, ratePerc, saving) {
    assert(startAge <= stopAge, "Stop age must be larger than, or equal start age");

    const span = stopAge - startAge;
    const projections = [[startAge, 0]];

    for(let i = 1; i <= span; i++) {
        const [age, prev] = projections[i-1];
        projections.push([age+1, amount(prev, ratePerc, saving)]);
    }

    return projections;
}