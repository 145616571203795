import "./App.css";
import { Grid, TextField, Button } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { projection, toCents } from "./lib";
import Chart from "./Chart";

function App() {
  const [startAge, setStartAge] = useState("");
  const [stopAge, setStopAge] = useState("");
  const [saving, setSaving] = useState("");
  const [interest, setInterest] = useState("");

  const [projections, setProjections] = useState(undefined);
  const [cashProjections, setCashProjections] = useState(undefined);

  return (
    <Grid container className={classNames("main-container")} spacing={5}>
      <Grid item lg={2} xs={12}>
        <Grid container spacing={2}>
          <Grid item lg={12}>
            <TextField id="outlined-basic" label="start age" variant="outlined"
              value={startAge} onChange={e => setStartAge(e.target.value)} />
          </Grid>
          <Grid item lg={12}>
            <TextField id="outlined-basic" label="stop age" variant="outlined"
              value={stopAge} onChange={e => setStopAge(e.target.value)} />
          </Grid>
          <Grid item lg={12}>
            <TextField id="outlined-basic" label="saving per month" variant="outlined"
              value={saving} onChange={e => setSaving(e.target.value)} />
          </Grid>
          <Grid item lg={12}>
            <TextField id="outlined-basic" label="interest per year" variant="outlined"
              value={interest} onChange={e => setInterest(e.target.value)} />
          </Grid>
          <Grid item lg={12}>
            <Button variant="contained" onClick={
              () => {
                setProjections(
                  projection(
                    parseInt(startAge),
                    parseInt(stopAge),
                    parseInt(interest) / 100.0,
                    toCents(parseInt(saving) * 12)));
                setCashProjections(
                  projection(
                    parseInt(startAge),
                    parseInt(stopAge),
                    0,
                    toCents(parseInt(saving) * 12)));
              }}>
              Calculate
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={10} xs={12} className={classNames("output-container")}>
        {
          projections ? (
            <Chart projections={projections} cashProjections={cashProjections}/>
          ) : (<></>)
        }
      </Grid>
    </Grid>
  );
}

export default App;
